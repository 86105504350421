@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 700;
  src: local('Campton'), url('https://fonts.cdnfonts.com/s/45240/CamptonBold.woff') format('woff');
}

@font-face {
  font-family: 'Campton';
  font-style: normal;
  font-weight: 400;
  src: local('Campton'), url('https://fonts.cdnfonts.com/s/45240/CamptonBook.woff') format('woff');
}

@font-face {
  font-family: 'Staatliches';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/staatliches/v11/HI_OiY8KO6hCsQSoAPmtMYebvpA.woff2") format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVI.woff2") format('woff2');
}
